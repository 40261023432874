#Communities {
    min-height: 100vh;
    width: 100%;
    padding: 40px 0;
}

.div_Communities {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}

.div_Communities>h1 {
    color: var(--title-color-primary);
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5rem;
}

.container-communities,
.container-allies {
    display: flex;
    flex-direction: column;
}

.container-community {
    display: flex;
}

.container-community.left {
    flex-direction: row;
}

.container-community.right {
    flex-direction: row-reverse;
}

.logo-social-container,
.logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    min-width: 200px;
}

.community-logo,
.ally-logo {
    width: 260px;
    height: auto;
    object-fit: contain;
}

.social-links {
    display: flex;
    gap: 10px;
    margin-top: 8px;
}

.social-links a {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--title-color-secondary);
}

.social-links img {
    width: 25px;
    height: 25px;
}

.social-links img:hover {
    border-radius: 50%;
    box-shadow: 0 0 10px 1px rgba(255, 255, 255, 0.7);
}

.community-data,
.ally-data {
    flex: 1;
    padding: 20px;
    display: flex;
    align-items: center;
}

.community-data p,
.ally-data p {
    margin: 0;
    line-height: 1.6;
}

.container-allies {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    justify-items: center;
}

.ally-item {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.ally-logo {
    width: 180px;
    height: auto;
    object-fit: contain;
    margin-bottom: 0px;
}

.ally-social-links {
    display: flex;
    gap: 10px;
    margin-top: 8px;
}

.ally-social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.ally-social-links img {
    width: 25px;
    height: 25px;
}

.ally-social-links img:hover {
    border-radius: 50%;
    box-shadow: 0 0 10px 1px rgba(255, 255, 255, 0.7);
}


@media (max-width: 768px) {

    .container-community,
    .container-ally,
    .container-community.left,
    .container-community.right,
    .container-ally.left,
    .container-ally.right {
        flex-direction: column;
    }

    .logo-social-container,
    .logo-container {
        width: 100%;
    }

    .community-logo,
    .ally-logo {
        width: 120px;
    }

    .social-links,
    .ally-social-links {
        margin-top: 6px;
    }
}