.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    padding-top: 5px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    transition: background-color 1s ease;
  }
  .navbar::before {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rebeccapurple;
  }
  .logoHeader {
    width: 50px;
    padding: 7px;
    justify-self: center;
    align-self: center;
}

  .navbar-brand {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-right: 4%;
    padding-left: 4%;

  }
  
  .navbar-menu a {
    text-decoration: none;
    color: var(--title-color-secondary);
    font-size: 20px;
    font-family: 'Roboto';
    letter-spacing: 1px;
  }
  
  .menu-icon {
    display: none;
    flex-direction: column;
    cursor: pointer;
    border-color: transparent;
    background-color: transparent;
  }
  
  .menu-icon span {
    width: 25px;
    height: 3px;
    background-color: #FFBC00;
    margin: 4px 0;
    transition: all 0.3s ease-in-out;
  }
  
  .navbar-menu {
    list-style: none;
    display: flex;
    font-family: "Coiny";
    color: black;
    gap: 30px;
    padding-right: 4%;
  }

  .navbar-menu>ul {
    margin-right: 20px;
  }

  .navbar-menu>li {
    padding-left: 0px;
    text-shadow: 0px 0px 8px #000000;
  }
  
  .navbar-menu.show {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 85%;
    left: 0;
    padding: 20px 0px;
    width: 100%;
    text-align: center;
    background-color: rgba(2, 12, 46, 0.5);
    backdrop-filter: blur(80px);
  }

  .navbar-menu a:hover {
    color: var(--title-color-primary);
  }

  @media (max-width: 990px) {
    .navbar-menu {
      display: none;
    }
  
    .menu-icon {
      display: flex;
    }
    .navbar-menu a {
      font-size: 24px;
    }
  }

  .navbar.scrolled {
    background-color: rgba(0,0,0,.5);
    backdrop-filter: blur(80px);
  }