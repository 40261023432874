/* Sección Codigo de Conducta */

.title {
    width: 100%;
    text-align: center;
    font-family: 'American';
    font-size: 52px;
    color: var(--title-color-primary);
    margin: 0;
}

.subtitle {
    width: 100%;
    font-family: 'Roboto';
    font-size: 28px;
    color: var(--title-color-primary);
    margin-bottom: 24px;
    margin-top: 24px;
}

.text {
    width: 100%;
    font-family: 'Roboto';
    font-size: 18px;
    color: var(--paragraph-color-primary);
}

.div_COC {
    padding: 4% 8% 4% 8%;
}

.div_COC>h1 {
    text-align: center;
}

.conduct-item ul {
    list-style-type: circle;
    display:flex;
    flex-direction: column;
}

.conduct-item li {
    padding: 0px 0px 0px 20px;
    font-family: 'Roboto';
    font-size: 16px;
    color: white;
}

.conduct-item p {
    text-align: left;
}

.COC>li {
    padding: 0px 0px 0px 20px;
    font-family: 'Roboto';
    font-size: 20px;
    color: white;
}

.asistente {
    background: radial-gradient(var(--background-color-secondary), var(--background-color-primary)) padding-box,
    linear-gradient(to right, var(--title-color-secondary), var(--title-color-primary)) border-box;
    /* border-radius: 60px; */
    border: 2px solid transparent;
    padding: 25px 40px;
    margin-bottom: 25px;
}

.asistente>h2 {
    color: transparent;
    margin: 0px 0px 5px 0px;
}

.speaker {
    background: radial-gradient(var(--background-color-secondary), var(--background-color-primary)) padding-box,
    linear-gradient(to right, var(--title-color-tertiary),var(--title-color-quaternary)) border-box;
    /* border-radius: 60px; */
    border: 2px solid transparent;
    padding: 25px 40px;
    margin-bottom: 25px;
}

.speaker>h2 {
    color: transparent;
    margin: 0px 0px 5px 0px;
}

.staffs {
    background: radial-gradient(var(--background-color-secondary), var(--background-color-primary)) padding-box,
    linear-gradient(to right, var(--title-color-primary), var(--title-color-secondary)) border-box;
    /* border-radius: 60px; */
    border: 2px solid transparent;
    padding: 25px 40px;
    margin-bottom: 25px;
}

.staffs>h2 {
    color: transparent;
    margin: 0px 0px 5px 0px;
}


ul {
    list-style-type: none;
    display:flex;
    flex-direction: row;
}

ul>li {
    font-family: 'Roboto';
}

li {
    padding: 0px 0px 0px 20px;
    font-family: 'Roboto';
    font-size: 20px;
}

@media (max-width: 768px) {
    .conduct-item li {
        font-size: 14px;
    }
    .title {
        font-size: 42px;
    }

    .subtitle {
        font-size: 24px;
    }

    .text {
        font-size: 16px;
    }

    .asistente {
        border-radius: 40px;
        border: 2px solid transparent;
        padding: 20px 25px 10px 25px;
    }

    .asistente>h2 {
        font-size: 25px;
    }

    .speaker {
        border-radius: 40px;
        border: 2px solid transparent;
        padding: 20px 25px 10px 25px;
    }

    .speaker>h2 {
        font-size: 25px;
    }

    .staffs {
        border-radius: 40px;
        border: 2px solid transparent;
        padding: 20px 25px 10px 25px;
    }

    .staffs>h2 {
        font-size: 25px;
    }
}
