.footer-line {
    width: 100%;
    height: 2px;
    background-color: var(--title-color-primary);
    color: var(--title-color-primary);
    margin-top: 20px;
}

.footer {
    padding: 20px 0;
    text-align: center;
    position: relative;
    font-family: 'Roboto', sans-serif;
}

.footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    font-weight: bold;
}

.footer-logo {
    margin-bottom: 20px;
}

.footer-nav {
    display: flex;
    gap: 100px;
    margin-bottom: 20px;
}

.footer-link {
    color: var(--title-color-secondary);
    text-decoration: none;
    font-size: 16px;
}

.footer-link:hover {
    text-decoration: underline;
    color: var(--title-color-primary);
}

.footer-icons {
    display: flex;
    gap: 30px;
    margin-bottom: 5px;
}

.social-icon {
    width: 30px;
    height: 30px;
}

.social-icon:hover {
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 0 10px 1px rgba(255, 255, 255, 0.7);
}

.footer-social-handle {
    font-size: 14px;
    color: var(--title-color-primary);
}

@media (max-width: 780px) {
    .footer {
        padding: 15px 0;
        font-size: 14px;
    }

    .footer-container {
        margin-bottom: 30px;
    }

    .footer-nav {
        flex-direction: column;
        gap: 20px;
        margin-bottom: 30px;
    }

    .footer-link {
        font-size: 14px;
    }

    .footer-icons {
        gap: 20px;
    }

    .footer-social-handle {
        font-size: 12px;
    }

    .footer-line {
        margin-top: 10px;
    }

    .social-icon {
        width: 25px;
        height: 25px;
    }
}