#div_countdown h2 {
    text-align: center;
}

.countdown-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 600px;
    margin: auto;
    gap: 50px;
}

.countdown-item {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.countdown-number {
    font-family: 'American';
    font-size: 80px;
    color: var(--title-color-primary);
    text-align: center;
}

.countdown-label {
    font-family: 'American';
    font-size: 14px;
    letter-spacing: 2px;
    font-weight: bold;
    color: var(--title-color-primary);
}
@media (max-width: 768px) {
    .countdown-container {
        width: 70%;
        flex-wrap: wrap;
        gap: 0;
    }
    .countdown-item {
        text-align: center;
        bottom: 10px;
        width: 100%;
    }
}

@media (max-width: 575px) {
    .countdown-item {
        text-align: center;
        bottom: 10px;
        width: 100%;
    }
    .countdown-number {
        font-size: 50px;
    }
    
    .countdown-label {
        font-size: 18px;
    }

}