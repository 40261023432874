.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; 
  justify-content: center;
}

.container {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 40px;
}

.card {
  border: 2px solid;
  padding: 20px;
  border-radius: 8px;
  width: 250px;
  text-align: center;
  margin-top: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: transform 0.3s ease;
  padding-bottom: 60px;
}

.card-icon-container {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
}

.card-icon {
  width: 50px;
  height: 50px;
}

.card h4 {
  font-size: 1.5rem;
  margin-top: 30px;
}

.card-details {
  list-style-type: none;
  padding: 0;
  font-size: 0.9rem;
  color: #dbe2ef;
  margin: 5px 0 0;
  display: flex;
  flex-direction: column; 
  gap: 8px;
  text-align: left; /* Alinea el texto al lado izquierdo */
  align-items: flex-start;
}

.card-details li {
  padding: 10px;
  display: flex;
  position: relative; 
}

.button-container {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  display: none;
}

.card-button {
  background-color: transparent;
  width: 40%; 
  padding: 10px 20px;
  border: 2px solid;
  border-radius: 20px;
  color: inherit;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  
}

.card-button:hover {
  background-color: transparent;
}

@media (max-width: 575px) {
  ul {
    gap: 0;
  }
  li {
    font-size: 14px;
  }
  .card-details li{
    padding: 0;
  }
}

@media (max-width: 268px) {
  .card {
    width: 100%;
  }
}
