#Campus {
    position: relative;
}

.StarsBackground {
    position: absolute;
}

.div_campus {
    padding: 4% 4% 4% 4%;
    position: relative;
}

.div_campus>h1 {
    text-align: center;
}

.container-campus {
    display:flex;
    flex-direction: column;
    justify-content:center;
    margin: 50px 10% 15px 10%;
    
    overflow: hidden;
}

.logoUcatec {
    display: flex;
    flex-direction: column;
    justify-items: center;
    object-fit: contain;
    
    height: 300px;
}

.container-RRSS-campus {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    gap: 35px;
    margin-top: 30px;
}

.container-RRSS-campus > a > img{
    height: 40px;
    margin-bottom: 10px;
}

.container-RRSS-campus > a > img:hover{
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 0 20px 1px rgba(255, 255, 255, 0.7);
}

.container-RRSS-campus>a {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Montserrat';
    font-size: 20px;
    margin-bottom: 5px;
}

.SocialLink {
    color: aliceblue;
    text-align: center;
    height: 22px;
    font-family: 'American';
    font-size: 20px;
    letter-spacing: 1px;
    color: var(--title-color-primary);
}

.stars1 {
    animation: parpadeo3 6s infinite;
}

.stars2 {
    transform: scale(-1,-1);
    animation: parpadeo4 6s infinite;
}

@keyframes parpadeo3 {
    0%, 50% {
        opacity: 0.4;
    }
    25%, 75% {
        opacity: 0;
    }
    100% {
        opacity: 0.4;
    }
}

@keyframes parpadeo4 {
    0%, 50% {
        opacity: 0;
    }
    25%, 75% {
        opacity: 0.4;
    }
    100% {
        opacity: 0;
    }
}

@media (max-width: 1100px) {
    .container-campus{
        justify-content: center;
        align-items: center;
    }
    .logoUcatec {
        width: 80%;;
    }
    .container-RRSS-campus>a>img {
        width: 40px;
    }
}

@media (max-width: 768px) {
    .div_campus {
        margin-left: 0px;
        margin-right: 0px;
        padding: 4%;
    }
    .container-campus {
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
        
    }
    .logoUcatec {
        height: auto;
    }
    
    .container-RRSS-campus>a>img {
        width: 40px;
    }

    .stars1, .stars2 {
        display: none;
    }
}