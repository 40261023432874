:root {
    --title-color-primary: #FDBA12;
    --title-color-secondary: #ffffff;
    --title-color-tertiary: #00CEFF;
    --title-color-quaternary:  #344d7f;
    --paragraph-color-primary: #ffffff;
    --paragraph-color-secondary: #55d4e8; 
    --background-color-primary: #091626;
    --background-color-secondary: #0f4157;
    --background-color-tertiary: #233654;
    --background-color-quaternary: #0A1828;
    --decoration-color: #FEBB02;
    --decoration-color-secondary: #3376de;
}
@font-face {
    font-family: 'American';
    src: url('../../public/fonts/AmericanTypewriterRegular.ttf');
}

@font-face {
    font-family: 'Roboto';
    src: url('../../public/fonts/Roboto-Regular.ttf');
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    padding: 0px;
    margin: 0px;
    background-color: rgb(4, 33, 58);
    font-family: "Montserrat", sans-serif;
}

h1 {
    font-family: 'American';
    font-size: 5rem;
    color: var(--paragraph-color-primary);
    margin: 0;
    text-align: center;
}

h2 {
    font-family: 'American';
    font-size: 52px;
    letter-spacing: 2px;
    color: var(--title-color-primary); 
    margin: 0;
    margin-bottom: 30px;
    text-align: center;
    text-shadow: 0px 0px 8px #000000;
}

h3 {
    font-family: 'Roboto';
    color: var(--title-color-primary);
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5rem;
}

p {
    font-family: 'Roboto';
    text-align: center;
    font-size: 20px;
    margin: 10px 0px;
    color: #ffffff;
}

a {
    text-decoration: none;
}

.button {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    font-family: 'American';
    color: var(--decoration-color);
    background-color: transparent;
    border: 1px solid var(--decoration-color);
    font-size: 20px;
    letter-spacing: 1px;
    padding: 10px 30px;
    border-radius: 32px;
    cursor: pointer;    
    transition: 0.3s;
    z-index: 10;
}

.button:hover {
    background: linear-gradient(to right, var(--title-color-quaternary),var(--title-color-tertiary));
    color: var(--paragraph-color-primary);
}

/* start home */

#background {
    width: 100vw;
    height: 100vh;
    position: relative;
    background: linear-gradient(to bottom, var(--background-color-primary), var(--background-color-secondary));
    z-index: -0;
    transition: height 2s ease;
}

.BackgroundElements {
    width: 100vw;
    height: 100dvh;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: -1;
    overflow: hidden;
    transition: height 2s ease;
}

.BackgroundElements>img {
    position: absolute;
}

.BackgroundElements-stars {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#stars1 {
    position: absolute;
    width: 90%;
    height: 60%;
    animation: parpadeo1 6s infinite;
}

#stars2 {
    position: absolute;
    width: 100%;
    top: 0;
    transform: scale(-1,-1);
    animation: parpadeo2 6s infinite;
}

@keyframes parpadeo1 {
    0%, 50% {
        opacity: 0.7;
    }
    25%, 75% {
        opacity: 0;
    }
    100% {
        opacity: 0.7;
    }
}

@keyframes parpadeo2 {
    0%, 50% {
        opacity: 0;
    }
    25%, 75% {
        opacity: 0.7;
    }
    100% {
        opacity: 0;
    }
}

#moon {
    position: absolute;
    height: 500px;
    width: 500px;
    top: 10px;
    left: auto; 
    right: -150px;
    transition: left 5s ease, right 5s ease;
}

#christ {
    height: 450px;
    left: 0;
    translate: -20px;
    bottom: -100px;
}

.Intro {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    gap: 30px;
}

.mainContainer {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    transition: height 2s ease;
}

#sectionsContainer {
    display: flex;
    flex-direction: column;
}

.sectionInicio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100dvh;
}

.contentLogo {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 12px;
}

.sectionInicio span {
    text-shadow: 0px 0px 8px #000000;
}

.contentDescription {
    display: flex;
    justify-content: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
}

.contentDescription>a>img {
    width: 40px;
    height: fit-content;
}

.contentDescription>a {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Roboto';
    letter-spacing: 1px;
    font-size: 24px;
    margin-bottom: 5px;
    color: var(--paragraph-color-primary);
    padding-top: 2%;
    gap: 15px;
}

#sede {
    display: none;
}

.contentBody  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 16%;
    padding-right: 16%;
}

.logo_inicio {
    width: 40%;
}

.CommingSoon {
    width: 20%;
}

.fondo  {
    position: absolute;
    
    bottom: -20px;
    width: 100%;
}
/* end home */

.IntroContent {
    display: flex;
    flex-direction: column;
    gap: 88px;
    padding-bottom: 75px;
    position: relative;
    z-index: 2;
    background: linear-gradient(to bottom, var(--background-color-tertiary), var(--background-color-quaternary));
    transition: translate 2s ease;
}

.EventDescription {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 56px;
}

.EventDescription img {
    width: 20%;
    /* -webkit-filter: drop-shadow(5px 5px 5px #ffffff);
    filter: drop-shadow(0px 0px 15px rgb(255, 255, 255, 0.5)); */
}

.EventDescription p {
    text-align: left;
}

.EventDescription p > span {
    color: #55D4E8;
}

.information {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.contentBody-tickets {
    display: flex;
    gap: 10px;
}

.contentBody-tickets p {
    font-family: 'American';
    color: var(--decoration-color);
    font-size: 20px;
}

@media (max-width: 768px) {
    .mainContainer {
        padding-top: 0;
    }
    p {
        font-size: 15px;
    }
    .contentLogo {
        flex-direction: column;
        padding-top: 20%;
        padding-bottom: 0px;
        gap: 20px;
    }
    .contentDescription>a>img {
        width: 35px;
        height: fit-content;
        /* margin-right: 10px; */
    }
    .contentDescription {
        align-items: flex-start;
        flex-direction: row;
        gap: 35px;
    }
    .contentDescription>a {
        font-size: 20px;
        
        gap: 10px;
    }
    .contentBody  {
        padding-left: 12%;
        padding-right: 12%;
    }
    .button {
        margin-top: 20px;
    }
}

@media (max-width: 575px) {
    h1 {
        font-size: 35px;
    }
    h2 {
        margin-top: 20px;
        font-size: 35px;
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
        padding: 0 20px;
    }
    p {
        font-size: 14px;
    }
    .contentLogo {
        flex-direction: column;
        padding-top: 24%;
    }
    .contentLogo > img {
        width: 80%;
    }
    .contentDescription>a>img {
        width: 25px;
        height: fit-content;
    }
    .contentDescription {
        align-items: flex-start;
        flex-direction: column;
        gap: 0px;
    }
    .contentDescription>a {
        font-size: 16px;
    }
    .contentBody  {
        padding-left: 8%;
        padding-right: 8%;
    }
    .contentBody-tickets {
        flex-direction: column;
    }
    .contentBody-tickets a {
        width: 100%;
    }
    .contentBody-tickets a .button {
        width:auto;
    }
    .contentBody-tickets p {
        font-size: 14px;
    }

    .button {
        margin-top: 20px;
    }
    #moon{
        height: 350px;
        object-fit: contain;
    }
    #christ {
        width: 150%;
        object-fit: contain;
        object-position:bottom;
        transform: translateY(30px); 
        right: 0;
        bottom: 0;
    }
    .EventDescription {
        flex-direction: column;
        gap: 10px;
    }
    .EventDescription > img {
        width: 40%;
    }
    .information {
        gap: 0;
    }
    .button {
        margin-top: 5px;
    }
    .IntroContent {
        gap: 30px;
    }
    #stars1 {
        display: none;
    }
    #stars2 {
        display: none;
    }
}

.hidden{
    display: none;
}